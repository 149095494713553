(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/widgets/casino-teaser/views/casino-teaser.js') >= 0) return;  svs.modules.push('/widgets/casino-teaser/views/casino-teaser.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.widget=_svs.widget||{};
_svs.widget.casino_teaser=_svs.widget.casino_teaser||{};
_svs.widget.casino_teaser.templates=_svs.widget.casino_teaser.templates||{};
svs.widget.casino_teaser.templates.casino_teaser = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"shouldRenderWidget") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":40,"column":9}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"widget-default widget-full-width widget-full-height grid-row widget-casino-teaser fc-white"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"styling") : depth0)) != null ? lookupProperty(stack1,"format") : stack1),"===","tall",{"name":"compare","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":104},"end":{"line":3,"column":246}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"currentWidget"))) && lookupProperty(stack1,"inCasino")),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":3,"column":246},"end":{"line":3,"column":378}}})) != null ? stack1 : "")
    + "\">\n    <div class=\"widget-casino-teaser-list col-xs-12\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"styling") : depth0)) != null ? lookupProperty(stack1,"showLogo") : stack1),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":6},"end":{"line":9,"column":13}}})) != null ? stack1 : "")
    + "      <div class=\"f-content padding-xs-left-1 padding-md-left-2 widget-casino-teaser-header\">\n        <div class=\"widget-casino-teaser-header-headings\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"styling") : depth0)) != null ? lookupProperty(stack1,"title") : stack1),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":10},"end":{"line":14,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"styling") : depth0)) != null ? lookupProperty(stack1,"subTitle") : stack1),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":10},"end":{"line":17,"column":17}}})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"widget-casino-teaser-header-link\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"link") : depth0)) != null ? lookupProperty(stack1,"url") : stack1),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":10},"end":{"line":27,"column":17}}})) != null ? stack1 : "")
    + "        </div>\n      </div>\n\n      <ul class=\"widget-casino-teaser-games js-widget-casino-teaser-games grid-row padding-xs-left-1 padding-md-left-2\n        "
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"styling") : depth0)) != null ? lookupProperty(stack1,"format") : stack1),"===","doubleRows",{"name":"compare","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":8},"end":{"line":32,"column":98}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"games") : depth0),{"name":"each","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":8},"end":{"line":35,"column":17}}})) != null ? stack1 : "")
    + "      </ul>\n\n    </div>\n  </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " widget-casino-teaser-tall"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"styling") : depth0)) != null ? lookupProperty(stack1,"showOrder") : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":170},"end":{"line":3,"column":234}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return " widget-casino-teaser-tall-order";
},"6":function(container,depth0,helpers,partials,data) {
    return "";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " widget-casino-teaser-outside-casino widget-casino-teaser-"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"styling") : depth0)) != null ? lookupProperty(stack1,"colorScheme") : stack1), depth0));
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <a href=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"casinoHomeUrl") || (depth0 != null ? lookupProperty(depth0,"casinoHomeUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"casinoHomeUrl","hash":{},"data":data,"loc":{"start":{"line":6,"column":15},"end":{"line":6,"column":32}}}) : helper)))
    + "\" class=\"casino-logo-outside-header align-center margin-v-4\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-casino-logo-logo"),depth0,{"name":"components-casino-logo-logo","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "      </a>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <h2 class=\"f-section-title widget-casino-teaser-title"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"styling") : depth0)) != null ? lookupProperty(stack1,"showLogo") : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":65},"end":{"line":13,"column":136}}})) != null ? stack1 : "")
    + "\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"styling") : depth0)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "</h2>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return " widget-casino-teaser-title-logo-visible";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <h3 class=\"widget-casino-teaser-sub-title\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"styling") : depth0)) != null ? lookupProperty(stack1,"subTitle") : stack1), depth0))
    + "</h3>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"link") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"===","linkAbove",{"name":"compare","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":12},"end":{"line":26,"column":24}}})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"link") : depth0)) != null ? lookupProperty(stack1,"url") : stack1), depth0))
    + "\" class=\"widget-casino-teaser-link js-widget-casino-teaser-link padding-xs-right-1 padding-md-right-2\" title=\"Visa alla "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"styling") : depth0)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "\">\n              "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"link") : depth0)) != null ? lookupProperty(stack1,"text") : stack1), depth0))
    + "\n              <i class=\"icon icon-100 icon-menu-right\"></i>\n            </a> \n";
},"20":function(container,depth0,helpers,partials,data) {
    return "widget-casino-teaser-double-rows";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"widget-casino_teaser-game"),depth0,{"name":"widget-casino_teaser-game","hash":{"showOrder":((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"currentWidget"))) && lookupProperty(stack1,"styling"))) && lookupProperty(stack1,"showOrder")),"format":((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"currentWidget"))) && lookupProperty(stack1,"styling"))) && lookupProperty(stack1,"format")),"area":((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"currentWidget"))) && lookupProperty(stack1,"trackingArea")),"showFavorite":((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"currentWidget"))) && lookupProperty(stack1,"inCasino")),"game":depth0,"index":(data && lookupProperty(data,"index"))},"data":data,"indent":"          ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"currentWidget") : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":41,"column":9}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});
Handlebars.partials['widget-casino_teaser-casino_teaser'] = svs.widget.casino_teaser.templates.casino_teaser;
})(svs, Handlebars);


 })(window);